import yuu from './yuu.png';
import './App.css';
import Marquee from "react-fast-marquee";

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
      <h1>阿悠有棍仲要係BIG JJ</h1>
        <img src={yuu} className="Yuu" alt="yuu" />
      </header> */}
      <Marquee speed={400}>
        <div className="Marquee">
          <div className='banner'>
          　熱烈慶賀小悠BB出席動漫節
            </div>
        </div>
      </Marquee>
    </div>
  );
}

export default App;
